body {
	background-image: url(images/lgi01a201411091900.jpg);
	overscroll-behavior: contain;
    overflow-y: hidden;
  }

.svg-container {
	text-align:center;
}

.svg-content {
	display: inline-block;
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	background-color : black;
}

